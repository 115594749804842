<template>
  <div>
    <AppHeader />
    <TTView v-if="blocks">
      <VRow
        v-for="block in blocks"
        :key="block.id"
      >
        <template v-if="blockTypes.block.includes(block.renderType)">
          <QuestionBlock
            :data-test-value="`block_id_${block.id}`"
            :block="block"
          />
        </template>
      </VRow>

      <VSnackbar
        v-if="isTestingSurvey"
        timeout="-1"
        top
        centered
        outlined
        color="#EBECED"
        content-class="tt-black--text"
        :value="true"
      >
        <VIcon
          color="tt-red"
          size="20"
          class="mr-2"
        >
          $exclamationCircle
        </VIcon>
        <span class="text-body-1 font-weight-medium">
          {{ $t('testingSnackBarTitle') }}
        </span>
      </VSnackbar>
    </TTView>
  </div>
</template>

<script>
import { blockTypes, SurveyStatus } from '@/utils/constants';
import { mapMutations } from 'vuex';
import { toggleLoading, updateProgressBar } from '@/plugins/vuex/mutationTypes';
import * as apiService from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import QuestionBlock from '@/components/app/QuestionBlock.vue';
import AppHeader from '@/components/app/AppHeader.vue';
import TTView from '@/components/TTView.vue';

export default {
  name: 'UserSurveyEnd',
  components: { AppHeader, TTView, QuestionBlock },
  inject: ['Names'],

  data() {
    return {
      blocks: null,
      blockTypes,
      SurveyStatus,
    };
  },

  computed: {
    userSurveySlug() {
      return this.$route.params.slug;
    },
    isTestingSurvey() {
      return Boolean(this.$route.query.testing);
    },
  },

  async created() {
    this[toggleLoading](true);
    try {
      const {
        data: {
          finalPage: {
            blocks,
            surveyName,
            status,
          },
        },
      } = await apiService.surveyFinalPageGet(this.userSurveySlug, {
        params: {
          language: this.$i18n.locale,
        },
      });

      if (status === SurveyStatus.FINISHED) {
        this.$router.push({ name: this.Names.R_USER_SURVEY_FINISHED });
        return;
      }
      this.blocks = blocks;
      this[updateProgressBar]({ surveyName });
    } catch (err) {
      errorHandler(err);
    } finally {
      this[toggleLoading](false);
    }
  },

  methods: {
    ...mapMutations([toggleLoading, updateProgressBar]),
  },
};
</script>
